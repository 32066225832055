import React from "react";
import "../css/accordion.css";

const Accordion = () => {
    return (
        <>
            <div className="w-full md:w-5/6 mx-0 md:mx-auto px-0">
                {/* <p>
                    Open <strong>multiple</strong>
                </p> */}
                <div className="shadow-md">
                    <div className="tab w-full overflow-hidden border-t">
                        <input
                            className="absolute opacity-0 "
                            id="tab-multi-one"
                            type="checkbox"
                            name="tabs"
                        />
                        <label
                            className="block p-5 leading-normal cursor-pointer text-lg"
                            htmlFor="tab-multi-one"
                        >
                            Dove è attivo il servizio di consegna delle
                            cassette?
                        </label>
                        <div className="tab-content overflow-hidden border-l-2 bg-gray-100 border-black leading-normal">
                            <p className="p-5 text-justify">
                                Il servizio di consegna delle cassette di frutta
                                e verdura è attivo nelle città di Roma, Milano,
                                Brescia e nell'area del Lago di Garda.
                            </p>
                        </div>
                    </div>
                    <div className="tab w-full overflow-hidden border-t">
                        <input
                            className="absolute opacity-0"
                            id="tab-multi-two"
                            type="checkbox"
                            name="tabs"
                        />
                        <label
                            className="block p-5 leading-normal cursor-pointer text-lg"
                            htmlFor="tab-multi-two"
                        >
                            Quanto costa la spedizione?
                        </label>
                        <div className="tab-content overflow-hidden border-l-2 bg-gray-100 border-black leading-normal">
                            <p className="p-5 text-justify">
                                La spedizione delle cassette di frutta e verdura
                                ha un costo di €7,00 per gli ordini fino a
                                €59,00. Per gli ordini superiori a €59,00 la
                                spedizione è gratuita.
                            </p>
                        </div>
                    </div>
                    <div className="tab w-full overflow-hidden border-t">
                        <input
                            className="absolute opacity-0"
                            id="tab-multi-three"
                            type="checkbox"
                            name="tabs"
                        />
                        <label
                            className="block p-5 leading-normal cursor-pointer text-lg"
                            htmlFor="tab-multi-three"
                        >
                            Come vengono spediti i prodotti?
                        </label>
                        <div className="tab-content overflow-hidden border-l-2 bg-gray-100 border-black leading-normal">
                            <p className="p-5 text-justify">
                                I prodotti vengono spediti tramite trasporto
                                dedicato refrigerato e consegnati direttamente
                                al tuo domicilio. Puoi effettuare il tuo ordine
                                durante tutta la settimana, noi raccogliamo i
                                prodotti dai nostri campi il martedì della
                                settimana successiva e ti consegnamo la cassetta
                                entro 48h dalla raccolta.
                            </p>
                        </div>
                    </div>
                    <div className="tab w-full overflow-hidden border-t">
                        <input
                            className="absolute opacity-0"
                            id="tab-multi-four"
                            type="checkbox"
                            name="tabs"
                        />
                        <label
                            className="block p-5 leading-normal cursor-pointer text-lg"
                            htmlFor="tab-multi-four"
                        >
                            Posso decidere cosa inserire nella box?
                        </label>
                        <div className="tab-content overflow-hidden border-l-2 bg-gray-100 border-black leading-normal">
                            <p className="p-5 text-justify">
                                La composizione di ogni cassetta è data dalla
                                disponibilità dei prodotti dei nostri campi.
                                Ogni settimana troverai nelle cassette nuovi
                                prodotti in base alla loro stagionalità.
                            </p>
                        </div>
                    </div>
                    <div className="tab w-full overflow-hidden border-t">
                        <input
                            className="absolute opacity-0"
                            id="tab-multi-five"
                            type="checkbox"
                            name="tabs"
                        />
                        <label
                            className="block p-5 leading-normal cursor-pointer text-lg"
                            htmlFor="tab-multi-five"
                        >
                            Da dove provengono i prodotti? Che tipo di
                            trattamenti subiscono?
                        </label>
                        <div className="tab-content overflow-hidden border-l-2 bg-gray-100 border-black leading-normal">
                            <p className="p-5 text-justify">
                                Tutti i prodotti Passo Ladro provengono dalle
                                nostre terre in Sicilia, certificate biologiche
                                e coltivate unicamente tramite tecniche
                                eco-sostenibili. I prodotti crescono senza
                                l'utilizzo di pesticidi, diserbanti e componenti
                                chimici, e vengono raccolti al loro giusto
                                stadio di maturazione.
                            </p>
                        </div>
                    </div>
                    <div className="tab w-full overflow-hidden border-t">
                        <input
                            className="absolute opacity-0"
                            id="tab-multi-six"
                            type="checkbox"
                            name="tabs"
                        />
                        <label
                            className="block p-5 leading-normal cursor-pointer text-lg"
                            htmlFor="tab-multi-six"
                        >
                            Cosa succede se non sono a casa quando passa il
                            corriere?
                        </label>
                        <div className="tab-content overflow-hidden border-l-2 bg-gray-100 border-black leading-normal">
                            <p className="p-5 text-justify">
                                Non ti preoccupare, il nostro servizio clienti è
                                pronto per ogni tua esigenza. Qualora non sia
                                possibile recapitarti il pacco, sarà nostra
                                premura organizzare una riconsegna secondo la
                                tua disponibilità.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Accordion;
