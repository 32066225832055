import React from "react";
import { graphql, StaticQuery } from "gatsby";
import Img from "gatsby-image";

const Mix = () => (
    <StaticQuery
        query={graphql`
            query {
                file(relativePath: { eq: "cst-mix-s-pl-1-min-scaled.jpg" }) {
                    childImageSharp {
                        # Specify the image processing specifications right in the query.
                        # Makes it trivial to update as your page's design changes.
                        fluid(maxWidth: 1000) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        `}
        render={(data) => (
            <Img
                fluid={data.file.childImageSharp.fluid}
                objectFit="contain"
                // objectPosition="50% 50%"
                alt="Logo"
            />
        )}
    />
);

export default Mix;