import React from "react";
import { graphql, StaticQuery } from "gatsby";
import Img from "gatsby-image";

const Infographic = () => (
    <StaticQuery
        query={graphql`
            query {
                mobileImage: file(
                    relativePath: {
                        eq: "info_v.png"
                    }
                ) {
                    childImageSharp {
                        fluid(maxWidth: 1000) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                desktopImage: file(
                    relativePath: {
                        eq: "info_h.png"
                    }
                ) {
                    childImageSharp {
                        fluid(maxWidth: 2000) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        `}
        render={(data) => {
            // Set up the array of image data and `media` keys.
            // You can have as many entries as you'd like.
            const sources = [
                data.mobileImage.childImageSharp.fluid,
                {
                    ...data.desktopImage.childImageSharp.fluid,
                    media: `(min-width: 768px)`,
                },
            ];

            return (
                <Img
                    fluid={sources}
                    // objectFit="cover"
                    // objectPosition="50% 50%"
                    alt="Infografica sulla spedizione delle cassette"
                />
            );
        }}
    />
);

export default Infographic;
