import React, { useRef } from "react";
import Layout from "../components/Layout";
import Modal from "../components/Modal";
// import HeroImage from "../components/HeroImage";
// import { Link } from "gatsby";
import Repubblica from "../components/Repubblica";
import Frutta from "../components/Frutta";
import Verdura from "../components/Verdura";
import Mix from "../components/Mix";
import Form from "../components/Form";
import Rating from "../components/Rating";
import Infographic from "../components/Infographic";
import Trustpilot from "../components/Trustpilot";
import RecensioneIsabella from "../components/RecensioneIsabella";
import Accordion from "../components/Accordion";
import "../css/scroller.css";
import RecensioneSara from "../components/RecensioneSara";
import RecensioneSimona from "../components/RecensioneSimona";
import Arance from "../components/Arance";
import Limoni from "../components/Limoni";
// import ReactPlayer from "react-player/file";

export default () => {
    const modalRef1 = useRef();

    return (
        <Layout>
            <main className="flex-grow flex flex-col justify-evenly m-auto py-2 px-4 md:px-32">
                <section className="flex flex-wrap flex-col-reverse md:flex-row items-center mt-6">
                    <div className="flex flex-col justify-between mx-auto md:ml-0 md:mr-auto max-w-xl">
                        <h1 className="px-4 pt-5 text-4xl md:text-5xl md:text-left text-center text-black font-serif tracking-wide">
                            Ami il Bio? Anche noi.
                        </h1>
                        <p className="px-4 mt-2 text-xl text-justify md:text-left text-gray-700 font-sans">
                            Cogliamo <span className="font-black">frutta</span>{" "}
                            e{" "}
                            <span className="font-black">
                                verdura biologica
                            </span>{" "}
                            appena prima di spedirla a casa tua.
                            <br />
                            Dai nostri campi in{" "}
                            <span className="font-black">Sicilia</span> alla tua{" "}
                            <span className="font-black">tavola</span>, in{" "}
                            <span className="font-black">due giorni</span>.
                            <br />
                            Certificati{" "}
                            <span className="font-black">Bioagricert&reg;</span>
                            .
                            <br />
                            Il servizio di consegna cassette riprenderà il{" "}
                            <span className="font-black">1 Giugno</span>. Vuoi
                            essere avvisato prima di tutti?
                            <span className="font-black">
                                {" "}
                                Iscriviti
                            </span> per{" "}
                            <span className="font-black">
                                rimanere aggiornato
                            </span>{" "}
                            e{" "}
                            <span className="font-black">
                                ricevere uno sconto
                            </span>
                            .
                        </p>
                        <div className="px-4 mt-8 md:text-left text-center">
                            {/* <div className="max-w-4xl mx-auto"> */}
                            <button
                                onClick={() => modalRef1.current.openModal()}
                                className="hover:bg-transparent bg-black shadow-lg hover:text-black text-white py-2 px-4 border hover:border-black border-transparent"
                            >
                                {/* <Link to="/iscriviti"> */}
                                <h2 className="max-w-4xl text-xl mx-auto uppercase font-bold font-sans">
                                    Ricevi il 10% di sconto
                                </h2>
                                {/* </Link> */}
                            </button>
                            {/* </div> */}
                        </div>

                        <p className="px-4 mt-8 text-xl text-gray-700 font-sans">
                            Parlano di noi:
                        </p>
                        <div className="px-4 mt-2 text-xl text-gray-700 font-sans text-center md:text-left">
                            <a
                                href="https://www.ilgusto.it/2021/04/22/news/sicilia_agricoltura_azienda_passo_ladro_pino_lops-297381732/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Repubblica />
                            </a>
                        </div>

                        <Modal ref={modalRef1}>
                            <Form />
                        </Modal>
                    </div>

                    <div
                        className="mx-auto md:mr-0 md:ml-auto w-full max-w-5xl md:w-1/2"
                        alt="Passo Ladro"
                    >
                        <video
                            controls
                            autoPlay
                            muted
                            playsInline
                            loop
                            height="720"
                            width="1280"
                        >
                            <source
                                src="./videos/video_cassette.webm"
                                type="video/webm"
                            />
                            <source
                                src="./videos/video_cassette.mp4"
                                type="video/mp4"
                            />
                            {/* https://vimeo.com/500950123 */}
                        </video>
                    </div>
                </section>

                {/* <section className="flex flex-wrap items-center justify-center mt-16">
                    <span className="scroll-icon">
                        <span className="scroll-icon__dot"></span>
                    </span>
                </section> */}

                <section className="flex flex-wrap items-center justify-center mt-5">
                    <div className="text-center mt-5 mx-0 w-full">
                        <h2 className="mb-2 px-4 text-5xl text-center text-black font-serif tracking-wide">
                            Come funziona
                        </h2>
                        <Infographic />
                    </div>
                </section>

                <section className="flex flex-wrap flex-row-reverse items-center mt-10 md:mt-20">
                    <div className="mx-auto md:mr-0 md:ml-auto max-w-md">
                        <h2 className="px-4 text-5xl md:text-left text-center text-black font-serif tracking-wide">
                            Cassetta di verdura
                        </h2>

                        <p className="px-4 mt-2 text-xl text-gray-800 font-sans">
                            da €27.50
                        </p>

                        <p className="px-4 mt-4 text-justify text-xl">
                            Salutare e genuina! La cassetta di verdura contiene
                            prodotti ricchi di fibre e nutrienti, a basso
                            apporto calorico, fondamentali per una sana e
                            corretta alimentazione. Grazie alla ricchezza
                            vitaminica e alla presenza di sali minerali, il
                            consumo giornaliero di un buon quantitativo di
                            ortaggi influisce positivamente sul nostro
                            organismo.
                        </p>

                        {/* <p className="px-4 mt-8 text-xl text-gray-700 font-sans">
                            As seen in
                        </p>
                        <p className="px-4 mt-2 text-xl text-gray-700 font-sans">
                            <a
                                href="#"
                                onClick={() => modalRef1.current.openModal()}
                            >
                                <Repubblica />
                            </a>
                        </p> */}
                        {/* <Modal ref={modalRef1}>
                            <h3>Modal title 1</h3>
                        </Modal> */}
                    </div>

                    <div
                        className="mx-auto mt-5 md:ml-0 md:mr-auto w-full max-w-2xl md:w-1/2"
                        alt="Passo Ladro"
                    >
                        <Verdura />
                    </div>
                </section>

                <section className="flex flex-wrap items-center mt-10 md:mt-20">
                    <div className="mx-auto md:ml-0 md:mr-auto max-w-md">
                        <h2 className="px-4 text-5xl md:text-left text-center text-black font-serif tracking-wide">
                            Cassetta di frutta
                        </h2>

                        <p className="px-4 mt-2 text-xl text-gray-800 font-sans">
                            da €27.50
                        </p>

                        <p className="px-4 mt-4 text-justify text-xl">
                            Energia e benessere per affrontare la vostra
                            giornata. La frutta è un alimento ad alta densità
                            nutritiva, ricco di acqua, fibre, sali minerali e a
                            basso contenuto di grassi. Il consumo giornaliero di
                            una porzione di frutta, grazie alle sue funzioni
                            mineralizzanti e vitaminizzanti, ti assicurerà tutto
                            il benessere e la vitalità.
                        </p>

                        {/* <p className="px-4 mt-4 text-xl text-gray-700 font-sans">
                            As seen in
                        </p>
                        <p className="px-4 mt-2 text-xl text-gray-700 font-sans">
                            <a
                                href="#"
                                onClick={() => modalRef1.current.openModal()}
                            >
                                <Repubblica />
                            </a>
                        </p> */}
                        {/* <Modal ref={modalRef1}>
                            <h3>Modal title 1</h3>
                        </Modal> */}
                    </div>

                    <div
                        className="w-full max-w-2xl mt-5 mx-auto md:mr-0 md:ml-auto md:w-1/2"
                        alt="Passo Ladro"
                    >
                        <Frutta />
                    </div>
                </section>

                <section className="flex flex-wrap flex-row-reverse items-center mt-10 md:mt-20">
                    <div className="mx-auto md:mr-0 md:ml-auto max-w-md">
                        <h2 className="px-4 text-5xl md:text-left text-center text-black font-serif tracking-wide">
                            Cassetta mista
                        </h2>

                        <p className="px-4 mt-2 text-xl text-gray-800 font-sans">
                            da €27.50
                        </p>

                        <p className="px-4 mt-4 text-justify text-xl">
                            Un mix di gusto! La cassetta di frutta e verdura è
                            pensata per chi non vuole rinunciare ai sapori e a
                            tutte le proprietà nutritive, coniugando salute e
                            soddisfazione. I prodotti all'interno, privi di
                            grassi e ricchi di vitamine, minerali e acqua, hanno
                            un ruolo essenziale per il mantenimento del tuo
                            stato di salute.
                        </p>

                        {/* <p className="px-4 mt-4 text-xl text-gray-700 font-sans">
                            As seen in
                        </p>
                        <p className="px-4 mt-2 text-xl text-gray-700 font-sans">
                            <a
                                href="#"
                                onClick={() => modalRef1.current.openModal()}
                            >
                                <Repubblica />
                            </a>
                        </p> */}
                        {/* <Modal ref={modalRef1}>
                            <h3>Modal title 1</h3>
                        </Modal> */}
                    </div>

                    <div
                        className="w-full max-w-2xl mt-5 mx-auto md:ml-0 md:mr-auto md:w-1/2"
                        alt="Passo Ladro"
                    >
                        <Mix />
                    </div>
                </section>

                <section className="flex flex-wrap items-center mt-10 md:mt-20">
                    <div className="mx-auto md:ml-0 md:mr-auto max-w-md">
                        <h2 className="px-4 text-5xl md:text-left text-center text-black font-serif tracking-wide">
                            Cassetta di arance
                        </h2>

                        <p className="px-4 mt-2 text-xl text-gray-800 font-sans">
                            €20.90
                        </p>

                        <p className="px-4 mt-4 text-justify text-xl">
                            Una carica di energia! Le arance Passo Ladro sono un
                            tripudio di profumi di Sicilia e vitamine, utili per
                            affrontare con la giusta carica la giornata. Le
                            nostre arance 100% biologiche sono benefiche per il
                            sistema immunitario, grazie all’abbondanza di
                            vitamina C. Non solo sono utili per il sistema
                            immunitario, grazie alla pectina, ma hanno anche
                            proprietà antistress. Un vero toccasana per la tua
                            salute!
                        </p>

                        {/* <p className="px-4 mt-4 text-xl text-gray-700 font-sans">
                            As seen in
                        </p>
                        <p className="px-4 mt-2 text-xl text-gray-700 font-sans">
                            <a
                                href="#"
                                onClick={() => modalRef1.current.openModal()}
                            >
                                <Repubblica />
                            </a>
                        </p> */}
                        {/* <Modal ref={modalRef1}>
                            <h3>Modal title 1</h3>
                        </Modal> */}
                    </div>

                    <div
                        className="w-full max-w-2xl mt-5 mx-auto md:mr-0 md:ml-auto md:w-1/2"
                        alt="Passo Ladro"
                    >
                        <Arance />
                    </div>
                </section>

                <section className="flex flex-wrap flex-row-reverse items-center mt-10 md:mt-20">
                    <div className="mx-auto md:mr-0 md:ml-auto max-w-md">
                        <h2 className="px-4 text-5xl md:text-left text-center text-black font-serif tracking-wide">
                            Cassetta di limoni
                        </h2>

                        <p className="px-4 mt-2 text-xl text-gray-800 font-sans">
                            €20.90
                        </p>

                        <p className="px-4 mt-4 text-justify text-xl">
                            Il sapore della Sicilia! I limoni Passo Ladro sono
                            alleati perfetti del sistema immunitario. Grazie
                            all’abbondanza di vitamina C, sono utili per la
                            salute della pelle e per favorire la digestione. I
                            limoni hanno inoltre un forte effetto antiossidante,
                            che li rendono uno tra gli alimenti anti-colesterolo
                            per eccellenza.
                        </p>

                        {/* <p className="px-4 mt-8 text-xl text-gray-700 font-sans">
                            As seen in
                        </p>
                        <p className="px-4 mt-2 text-xl text-gray-700 font-sans">
                            <a
                                href="#"
                                onClick={() => modalRef1.current.openModal()}
                            >
                                <Repubblica />
                            </a>
                        </p> */}
                        {/* <Modal ref={modalRef1}>
                            <h3>Modal title 1</h3>
                        </Modal> */}
                    </div>

                    <div
                        className="mx-auto mt-5 md:ml-0 md:mr-auto w-full max-w-2xl md:w-1/2"
                        alt="Passo Ladro"
                    >
                        <Limoni />
                    </div>
                </section>

                <h2 className="mt-10 md:mt-20 mx-0 mb-2 px-4 text-5xl text-center text-black font-serif tracking-wide">
                    Recensioni
                </h2>
                <section className="grid grid-flow-row grid-cols-1 md:grid-cols-3 gap-3">
                    <div className="flex flex-col justify-between border-black border-4 max-w-md justify-self-center">
                        <RecensioneIsabella />

                        {/* <h1 className="px-4 pt-5 text-5xl text-left text-black font-serif tracking-wide">
                            Recensione 1
                        </h1>
                        
                        <p className="px-4 mt-2 text-xl text-gray-700 font-sans">
                            Sottotitolo Sottotitolo Sottotitolo Sottotitolo
                        </p> */}

                        <p className="px-4 mt-8 font-bold italic text-xl">
                            "Ottima esperienza. Consegna veloce, prodotti di
                            ottima qualità e super buoni. Bellissimo anche il
                            packaging!"
                        </p>

                        <p className="px-4 mt-8 text-xl text-gray-700 font-sans italic">
                            - Isabella -
                        </p>

                        <div className="text-center">
                            <div className="px-4 mt-2 text-xl text-gray-700 font-sans">
                                <Rating />
                            </div>
                        </div>
                        <div className="text-right">
                            <div className="px-4 mt-2">
                                <Trustpilot />
                            </div>
                        </div>
                        {/* <Modal ref={modalRef1}>
                            <h3>Modal title 1</h3>
                        </Modal> */}
                    </div>
                    <div className="flex flex-col justify-between border-black border-4 max-w-md justify-self-center">
                        {/* <h1 className="px-4 pt-5 text-5xl text-left text-black font-serif tracking-wide">
                            Recensione 2
                        </h1>
                        <p className="px-4 mt-2 text-xl text-gray-700 font-sans">
                            Sottotitolo Sottotitolo Sottotitolo Sottotitolo
                        </p> */}

                        <RecensioneSimona />

                        <p className="px-4 mt-8 font-bold italic text-xl">
                            "Ottimo sito! Ho apprezzato moltissimo il manifesto
                            dei briganti etici. I prodotti, poi sono
                            eccezionali! Stupendo luogo in cui comprare cibo
                            salutare e gustoso."
                        </p>

                        <p className="px-4 mt-8 text-xl text-gray-700 font-sans italic">
                            - Simona -
                        </p>

                        <div className="text-center">
                            <div className="px-4 mt-2 text-xl text-gray-700 font-sans">
                                <Rating />
                            </div>
                        </div>
                        <div className="text-right">
                            <div className="px-4 mt-2">
                                <Trustpilot />
                            </div>
                        </div>
                        {/* <Modal ref={modalRef1}>
                            <h3>Modal title 1</h3>
                        </Modal> */}
                    </div>
                    <div className="flex flex-col justify-between border-black border-4 max-w-md justify-self-center">
                        {/* <h1 className="px-4 pt-5 text-5xl text-left text-black font-serif tracking-wide">
                            Recensione 3
                        </h1>
                        <p className="px-4 mt-2 text-xl text-gray-700 font-sans">
                            Sottotitolo Sottotitolo Sottotitolo Sottotitolo
                        </p> */}

                        <RecensioneSara />

                        <p className="px-4 mt-8 font-bold italic text-xl">
                            "La Sicilia a Milano! [...] i sapori e gli odori
                            della mia amata Sicilia a Milano in meno di 2
                            giorni. Verdura e frutta fresca e super gustosa!
                            Super consigliato"
                        </p>

                        <p className="px-4 mt-8 text-xl text-gray-700 font-sans italic">
                            - Sara -
                        </p>

                        <div className="text-center">
                            <div className="px-4 mt-2 text-xl text-gray-700 font-sans">
                                <Rating />
                            </div>
                        </div>
                        <div className="text-right">
                            <div className="px-4 mt-2">
                                <Trustpilot />
                            </div>
                        </div>
                        {/* <Modal ref={modalRef1}>
                            <h3>Modal title 1</h3>
                        </Modal> */}
                    </div>
                </section>

                <section className="flex flex-wrap items-center justify-center mt-10 md:mt-20">
                    <div className="text-center mt-5 w-full">
                        <h2 className=" mx-0 mb-2 px-4 pt-5 text-5xl text-center text-black font-serif tracking-wide">
                            Domande frequenti
                        </h2>
                        <Accordion />
                    </div>
                </section>

                <section className="flex flex-wrap items-center justify-center mt-10 md:mt-20 mb-10">
                    <div className="sm:px-2">
                        <h2 className="px-4 pt-5 text-5xl text-center text-black font-serif tracking-wide">
                            Il Vero Bio
                        </h2>
                        <p className="px-4 mt-2 text-xl text-center text-gray-700 font-sans">
                            Unisciti alla rivoluzione etica e sostenibile di
                            Passo Ladro!
                        </p>
                        <div className="px-4 mt-8 text-center">
                            {/* <div className="max-w-4xl mx-auto"> */}
                            <button
                                onClick={() => modalRef1.current.openModal()}
                                className="hover:bg-transparent bg-black shadow-lg hover:text-black text-white py-2 px-4 border hover:border-black border-transparent"
                            >
                                <h2 className="max-w-4xl text-xl mx-auto uppercase font-bold font-sans">
                                    Ricevi il 10% di sconto
                                </h2>
                            </button>
                            {/* </div> */}
                        </div>
                    </div>
                </section>

                {/* <div className="mx-auto relative px-0 lg:px-6">
                    <video controls autoPlay muted height="720" width="1280">
                        <source src="./videos/video.webm" type="video/webm" />
                        <source src="./videos/video.mp4" type="video/mp4" />
                        https://vimeo.com/500950123
                    </video>
                </div> */}

                {/* <div className="flex items-center p-3 mx-2 bg-white rounded shadow-xs sm:mx-auto">
                    <code className="text-gray-600 text-xs leading-xl">
                        gatsby new my-project-name
                        https://github.com/melanienolan/gatsby-starter-tailwind-css
                    </code>
                </div> */}
            </main>
        </Layout>
    );
};
